#slide-bg-analytica {
	background-color: transparent;
	color: #fff;
	width: 100%;
	min-height: calc(100vh - 70px);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	background-size: cover;
}

#analytica-particles {
	position: absolute;
	text-align: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	background-color: rgba(0,0,0,0.5);
}

#analytica-particles canvas {
	left: 0;
	top: 0;
}

#slide-analytica-content {
	z-index: 0;
}

#analytica table {
	table-layout: fixed;
	width: 100%;
}

#analytica table td {
	vertical-align: top;
	padding: 20px;
}

#analytica h5 {
	text-transform: uppercase;
	font-size: 13px;
	color: #666;
	font-weight: 400;
	letter-spacing: 1px;
}

#analytica table td h5 {
	text-transform: uppercase;
	font-size: 16px;
	color: #222;
	font-weight: 600;
	letter-spacing: 0.5px;
}

@media only screen and (max-width: 900px) {
	#analytica table td {
		display: block;
	}
  }