#manifesto h2 {
    font-size: 4.8vh;
}

@media only screen and (min-width: 900px) {
    #manifesto .scroll-col {
        width: 63%;
    }

    #manifesto .sticky-col {
        width: 30%;
    }
}