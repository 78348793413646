.swiperHistory {
    max-width: 100%;
    width: 850px;
    margin: 30px auto;
    padding-bottom: 20px;
}

.swiperHistory .swiper {
    padding-bottom: 20px;
}

.swiperHistory .swiper-slide {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 20px;
}

.swiperHistory .swiper-slide p {
    text-align: justify;
}