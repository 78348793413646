table.table-homemedia {
    width: 90%;
    max-width: 800px;
    table-layout: fixed;
}

table.table-homemedia td {
    text-align: center;
    vertical-align: top;
}

.tag--list--homemedia {
    overflow: hidden;
    margin-top: 40px;
}

.tag--list--homemedia .tag--item {
    display: inline-block;
    padding: 10px 17px;
    font-size: 16px;
    color: #111;
    background-color: #AFF24B;
    border-radius: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 6px;
    margin-bottom: 8px;
}

table.table-homemedia .green {
    color: #AFF24B;
    font-weight: 900;
}

@media only screen and (max-width: 900px) {
    table.table-homemedia {
        width: 100%;
    }

    table.table-homemedia h1 {
        font-size: 25px;
        line-height: 30px;
    }

    table.table-homemedia p {
        font-size: 12px;
        line-height: 20px;
    }
}