.dark-slide {
    background-color: #1A2446;
    width: 100%;
    overflow: hidden;
}

.bg-gradient-text {
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-backface-visibility: visible;
}

.gradient-1 {
    background-image: radial-gradient(32.63% 109.33% at 11.08% 100%, #5887da 0%, #6C54FF 53.17%, #323232 100%);
}

.gradient-2 {
    background-image: radial-gradient(100% 59.7% at 100% 73.79%, rgb(218, 110, 201) 0%, rgb(10, 32, 41) 71.7%, rgb(0, 99, 141) 100%);
}

.gradient-3 {
    background-image: radial-gradient(88.56% 225% at 48.21% 186.29%, rgb(255, 249, 108) 0%, rgb(255, 168, 88) 29.17%, rgba(255, 255, 255, 0.35) 85.72%), radial-gradient(63.42% 175.57% at 52.01% -21.25%, rgb(91, 196, 255) 22.96%, rgb(100, 112, 215) 39.81%, rgb(255, 255, 255) 71.45%);
}

.gradient-4 {
    background-image: radial-gradient(56.91% 100% at 70.4% 100%, rgb(255, 183, 152) 0%, rgb(129, 105, 169) 64.63%, rgb(0, 0, 0) 100%);
}

.gradient-5 {
    background-image: radial-gradient(100% 390.26% at 0% 27.42%, rgb(75, 93, 160) 0%, rgb(155, 202, 216) 70.88%, rgb(234, 213, 140) 100%);
}

.gradient-6 {
    background-image: radial-gradient(99.12% 140.62% at 81.45% 123.44%, rgb(124, 181, 207) 13.05%, rgb(51, 139, 177) 69.22%, rgb(0, 0, 0) 100%);
}

.gradient-7 {
    background-image: radial-gradient(65.77% 192.19% at 50.14% 149.22%, rgb(123, 97, 255) 18.75%, rgb(200, 157, 255) 35.97%, rgb(123, 97, 255) 56.18%, rgb(0, 0, 0) 100%);
}

.gradient-8 {
    background-image: radial-gradient(73.28% 429.92% at 77.89% 138.24%, rgba(33, 255, 122, 0.8) 18.4%, rgba(69, 231, 221, 0.8) 39.1%, rgba(0, 0, 0, 0.8) 168.56%);
}

.bg-gradient-text p {
    color: inherit;
}

.image-faded {
    position: relative;
    font-size: 0;
}

.fade-top-white {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.fade-top-black {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
}

.fade-bottom-black {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
}

h5.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

h5.flex span.line {
    width: 100%;
    height: 1px;
    background-color: #000;
    margin-left: 10px;
}

.blue {
    color: #5887da;
}