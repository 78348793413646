.showcase-item {
    box-shadow: rgb(0 0 0 / 17%) 0px 0px 15px 0px;
    vertical-align: top;
    border-radius: 0;
    overflow: hidden;
    padding: 0;
    margin: 10px;
    position: relative;
    background-color: #fff;
    text-align: center;
}

.showcase-item:after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #000;
    transition: .7s ease;
    opacity: 0.5;
    z-index: 1;
}

.showcase-item:hover:after {
    opacity: 0.5;
}

#site-container .showcase-item:hover h5, #site-container .showcase-item:hover p, #site-container .showcase-item:hover h2 {
    opacity: 1;
}

#site-container .showcase-item p {
    font-size: 16px;
    color: #fff;
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin: 0;
    padding: 0;
    opacity: 1;
    transition: .7s ease;
    z-index: 2;
    font-weight: 600;
}

#site-container .showcase-item h5 {
    color: #fff;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 600;
    position: absolute;
    top: 20px;
    left: 20px;
    margin: 0;
    padding: 0;
    opacity: 1;
    transition: .7s ease;
    z-index: 2;
}

#site-container .showcase-item h2 {
    color: #fff;
    font-size: 30px;
    font-weight: 300;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    right: 20px;
    margin: auto;
    padding: 0;
    opacity: 0;
    transition: .7s ease;
    z-index: 2;
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: left;
}

@media only screen and (max-width: 1400px) {
    .showcase-item {
        margin: 0px;
        min-height: 300px;
    }
    .showcase-item img {
        margin: 0px;
        min-height: 300px;
        width: 100%;
        object-fit: cover;
    }

    #site-container .showcase-item h2 {
        font-size: 18px;
        line-height: 30px;
    }
}
