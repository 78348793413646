@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

html,
body,
#site-container {
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

pre,
code {
    overflow: auto;
    max-width: 100%;
}

#site-container h1 {
    font-size: 7vh;
    line-height: 10vh;
    margin: 20px 0;
}

#site-container h2 {
    font-size: 4vh;
    line-height: 5.5vh;
    margin: 20px 0;
}

#site-container h3,
#site-container h4 {
    font-size: 3vh;
    line-height: 4.5vh;
    margin: 20px 0;
}

#site-container h5 {
    font-size: 28px;
    margin: 18px 0;
}

#site-container p,
#site-container blockquote,
#site-container li {
    font-weight: 300;
    line-height: 30px;
}

#site-container p.small {
    font-size: 14px;
    line-height: 22px;
}

#site-container p.bold {
    font-weight: 500;
}

blockquote {
    display: block;
    background: transparent;
    padding: 15px 20px 15px 54px;
    margin: 10px 0 20px;
    position: relative;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    line-height: 30px;
    color: #fff;
    text-align: justify;
    min-height: 40px;
    font-weight: 300;
}

blockquote::before {
    content: "\201C";
    font-family: Georgia, serif;
    font-size: 60px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    left: 10px;
    top: 5px;
}

blockquote::after {
    content: "\201C";
    font-family: Georgia, serif;
    font-size: 60px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    right: -15px;
    bottom: 8px;
    transform: rotate(180deg);
}

blockquote a {
    text-decoration: none;
    background: #eee;
    cursor: pointer;
    padding: 0 3px;
    color: #fff;
}

blockquote a:hover {
    color: #666;
}

blockquote em {
    font-style: italic;
}

@media (max-width: 700px) {
    #site-container h1 {
        font-size: 40px;
        line-height: 55px;
        margin: 20px 0;
    }

    h1 {
        font-size: 40px;
        line-height: 55px;
        overflow-wrap: break-word;
    }

    h2 {
        font-size: 35px;
        line-height: 46px;
        margin: 20px 0;
    }

    h3,
    h4 {
        font-size: 30px;
        line-height: 40px;
        margin: 20px 0;
    }

    h5 {
        font-size: 28px;
        margin: 18px 0;
    }
}

p.link a {
    text-decoration: underline;
}