.button {
	display: inline-block;
    outline: none;
    padding: 18px 28px;
    border: 1px solid #000;
    border-radius: 0;
    transition: all .2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 2;
    color: #000;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Lato,serif;
    background-color: transparent;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
}

.button:focus {
	outline: 0 !important;
}

.button:before {
	content: "";
	position: absolute;
	left: 50%;
	transform: translateX(-50%)scaleY(1)scaleX(1.25);
	top: 100%;
	width: 140%;
	height: 180%;
	background-color: rgba(160,160,160,0.6);
	border-radius: 50%;
	display: block;
	transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
	z-index: -1;
	outline: none;
}

.button:after {
	content: "";
	position: absolute;
	left: 55%;
	transform: translateX(-50%)scaleY(1)scaleX(1.45);
	top: 180%;
	width: 160%;
	height: 190%;
	background-color: rgba(160,160,160,0.6);
	border-radius: 50%;
	display: block;
	transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
	z-index: -1;
	outline: none;
}

.button:hover {
	color: #fff;
	border: 1px solid #000;
}

.button:hover::before {
	top: -35%;
	background-color: #000;
	transform: translateX(-50%)scaleY(1.3)scaleX(0.8);
}

.button:hover::after {
	top: -45%;
	background-color: #000;
	transform: translateX(-50%)scaleY(1.3)scaleX(0.8);
}

.button.light {
    filter: invert(1);
}

.button.light:hover {
}