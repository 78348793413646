.blog-container {
	background-color: #f4f4f4;
	overflow: hidden;
}

.blog--grid {
	grid-template-columns: 33.33% 33.33% 33.33%;
	grid-template-rows: auto auto auto;
	column-gap: 0px;
	row-gap: 0;
	display: grid;
	max-width: 1300px;
	width: 100%;
	margin: auto;
}

@media only screen and (max-width: 900px) {
	.blog--grid {
		grid-template-columns: 50% 50%;
		grid-template-rows: auto auto;
		column-gap: 0px;
		row-gap: 0;
		display: grid;
	}
}

@media only screen and (max-width: 550px) {
	.blog--grid {
		grid-template-columns: 100%;
		grid-template-rows: auto;
		column-gap: 0px;
		row-gap: 0;
		display: grid;
	}
}

.entry {
	position: relative;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
	width: 100%;
	box-sizing: border-box;
	overflow: hidden;
}

.entry table {
	width: 90%;
    table-layout: fixed;
    margin: auto;
    margin-top: 40px;
	margin-bottom: 30px;
    background-color: #fff;
    border-collapse: collapse;
	border-radius: 20px;
	height: calc(100% - 70px);
	overflow: hidden;
}

.entry thead td {
	padding: 0;
	font-size: 0;
}

.entry tbody td {
	padding: 15px 25px;
}

.entry .preview {
  display: block;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.entry .preview img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	margin: 0;
}

.entry .content {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 10px 10px 10px 30px;
	overflow: hidden;
	box-sizing: border-box;
	transition: all 0.2s ease-in;
}

.entry .content:hover {
	background-color: rgba(256,256,256,0.35);
}

.entry h3 {
    font-size: 24px;
    margin-top: 5px;
	cursor: pointer;
    line-height: 33px;
}

.entry h3 a {
	text-decoration: none;
	color: #000;
}

.entry p.date {
	font-weight: 800;
	color: #6e6e73;
	font-size: 12px;
	line-height: 1.3;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

.tags {
	overflow: hidden;
	padding: 3px 0;
  	margin-bottom: 0;
}

.tags span {
	font-size: 12px;
    display: inline-block;
    color: #6e6e73;
    margin-right: 5px;
    text-transform: uppercase;
    font-weight: 600;
}

.entry--page {
	margin: 0;
	width: 100%;
	padding: 50px 5%;
	box-sizing: border-box;
}

.entry--page p.date,
.entry--page h1,
.entry--page .content,
.entry--page .tags {
	margin-left: auto;
	margin-right: auto;
}

.entry--page .content img {
	max-width: 100%;
	height: auto;
}

.slickslider {
	margin-top: 30px;
	cursor: grab;
	margin-left: 60px;
	margin-right: 60px;
	padding: 0;
}

.entry--slide {
	background: rgba(126, 126, 126, 0.4);
	position: relative;
	overflow: hidden;
	display: block;
	margin: 16px;
	border: 0px solid rgba(126, 126, 126, 0.1);
	box-sizing: border-box;
}

.entry--slide--cover {
	height: 210px;
}

.entry--slide--cover img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.entry--slide--detail {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 5px;
	z-index: 1;
	background: rgba(0,0,0,0.3);
}

span.entry--slide--title {
	color: #fff;
	font-size: 24px;
	display: block;
	font-weight: 600;
}

span.entry--slide--title a {
	color: #fff;
	font-weight: 600;
}

.blog--grid .entry img {
	transition: all 200ms ease-in;
}

.blog--grid .entry:hover img {
	transform: scale(1.06);
}

@media only screen and (max-width: 550px) {
	.entry--page h1 {
        font-size: 30px;
        line-height: 42px;
    }
}