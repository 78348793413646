@media only screen and (max-width: 900px) {
    .sticky-col h2 {
        font-size: 4.7vh;
    }

    .scroll-col h2 {
        font-size: 3.7vh;
    }
}

ul.numbered {
    margin: 0;
    padding: 0;
}

ul.numbered li {
    margin: 0;
    list-style-type: none;
    transition: all 200ms ease-in;
    font-weight: 500;
    line-height: 38px;
    cursor: pointer;
}

ul.numbered li span.number {
    width: 35px;
    transition: all 200ms ease-in;
    display: inline-block;
    color: #5887da;
}

ul.numbered li:hover {
    opacity: 0.6;
}

ul.numbered li:hover span.number {
    width: 40px;
}