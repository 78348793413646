.customers-grid {
    width: 90%;
    margin: auto;
    max-width: 1600px;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: auto;
    grid-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
    grid-row-gap: 0;
    row-gap: 0;
    display: grid;
    background-color: #fff;
}

@media only screen and (max-width: 900px) {
    .customers-grid {
        grid-template-columns: 33.33% 33.33% 33.33%;
        grid-template-rows: auto;
        grid-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0;
        grid-row-gap: 0;
        row-gap: 0;
        display: grid;
        background-color: #fff;
    }
}

.customer-logo {
    padding: 0 8%;
    text-align: center;
}

.customer-logo img {
    width: 70%;
    max-width: 200px;
    margin: auto;
}

#slide-bg-home {
    background-color: rgb(53, 53, 75);
    background-size: cover;
    background-position: center;
    color: #fff;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

#slide-home-content {
    z-index: 0;
}

#reviews {
    background-color: rgb(72, 72, 222);
    background: linear-gradient(90deg, #0d1729 0%, #294780 100%);
    width: 100%;
    padding: 40px 100px;
}

#reviews-container {
    max-width: 950px;
    margin: auto;
}

#title-with-arrows {
    overflow: hidden;
    position: relative;
}

#reviews h2 {
    color: #fff;
}

#swiper-navigation-top {
    overflow: hidden;
    margin-bottom: 30px;
    font-size: 0;
    position: absolute;
    top: 20px;
    right: 35px;
}

#swiper-navigation-top .btn-icon {
    width: 48px;
    height: 48px;
    display: inline-block;
    border-radius: 48px;
    background-color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 20px;
    color: #528EFF;
    cursor: pointer;
    border: none !important;
    outline: none;
}

#swiper-navigation-top div#swiper-left {
    margin-right: 15px;
}

#swiper-navigation-top div.swiper-button-disabled {
    opacity: 0.6;
}

#reviews .swiper {
    width: 100%;
    margin: auto;
    padding-bottom: 25px;
}

#reviews table {
    width: 100%;
    table-layout: fixed;
    margin: auto;
    margin-top: 30px;
}

#reviews table td:nth-child(1) {
    text-align: left;
    padding: 0;
    vertical-align: top;
}

#reviews table td:nth-child(2) {
    text-align: left;
    padding: 15px;
    vertical-align: bottom;
}

#reviews table td h3 {
    margin-bottom: 6px;
    color: #fff;
    margin-top: 0;
    font-size: 20px;
}

#reviews table td p {
    color: #fff;
}

#reviews table td img {
    margin: auto;
    width: 100px;
    height: auto;
    object-fit: contain;
}

@media only screen and (max-width: 900px) {
    #reviews {
        padding: 40px 10px;
        text-align: center;
    }

    #swiper-navigation-top {
        position: static;
    }

    #reviews table td {
        display: block;
    }

    #reviews table td:nth-child(1) {
        text-align: center;
    }
}