.container-sticky {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container-sticky .sticky-col {
    width: 30%;
    padding-top: 0;
}

.container-sticky .scroll-col {
    width: 63%;
    padding-top: 0px;
}

@media only screen and (max-width: 900px) {
    .container-sticky .sticky-col,
    .container-sticky .scroll-col {
        width: 100%;
    }

    .container-sticky .sticky-col {
        background-color: #fff;
        padding-bottom: 20px;
        border-bottom: 0px solid #ddd;
    }
}

@media only screen and (min-width: 900px) {
    .container-sticky .sticky-col>div {
        position: sticky;
        top: 100px;
    }

    .container-sticky {
        padding-top: 90px;
    }
}