.contact-table {
    width: 100%;
    margin: 40px auto;
    vertical-align: top;
}

.contact-table input[type='text'],
.contact-table textarea {
    font-family: 'Lato', sans-serif;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
    display: block;
    width: 100%;
    border: 1px solid #1c274d;
    border-radius: 3px;
    background-color: #202d57;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    line-height: normal;
    outline: none;
}

.contact-table input[type='text']::placeholder,
.contact-table textarea::placeholder {
    color: #ccc;
}

.contact-table textarea {
    min-height: 250px;
    max-height: 100%;
    height: 100%;
}

.contact-table input[type='text']:focus,
.contact-table textarea:focus {
    border-color: #015cff;
}

@media only screen and (max-width: 1100px) {
    .contact-table td {
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }

    .contact-table table {
        width: 100%;
        max-width: 100%;
    }

    .contact-table table td {
        width: 100%;
        display: block;
    }
}

@media only screen and (min-width: 1100px) {
    .contact-table table td:nth-child(2) {
        padding-left: 15px;
    }
}