div.searchbar {
    border: 2px solid #c8c8fa;
    width: 400px;
    max-width: 90%;
    margin: 20px auto;
    overflow: hidden;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
}
div.searchbar input {
	border: 0;
    -webkit-appearance: none;
    outline: none;
    font-family: 'Lato', sans-serif;
    padding-left: 10px;
    font-size: 16px;
    line-height: 22px;
    color: #555;
    width: 100%;
}
div.searchbar form {
    width: 100%;
}