footer {
    text-align: center;
    padding: 40px 30px;
    background-color: #fff  ;
    border-top: 1px solid #eee;
    font-family: 'Lato', sans-serif;
}

footer p {
    font-size: 14px;
    font-weight: 300;
    color: #222;
    margin: 0;
    line-height: 26px;
}

footer a {
    text-decoration: none;
    color: #222;
}

footer a:hover {
    text-decoration: underline;
}

footer h6 {
    font-size: 14px;
    color: #666;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 15px;
}

footer hr {
    border-color: #bbb;
}

footer .footer-block {
    margin-bottom: 30px
}

footer .footer-block ul {
    margin: 0;
    padding: 0;
}

footer .footer-block li {
    list-style-type: none;
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    line-height: 26px;
}

footer p.small {
    font-size: 13px;
}