body header {
    --header-bg: #fff;
    /* header bg */
    --header-link-color: #000;
    --header-bg-mobile: #fff;
    font-family: 'Lato', sans-serif;
    --submenu-bg: #fff;
}

header {
    height: 70px;
    border-bottom: 0px solid #eee;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: var(--header-bg);
    z-index: 9;
    backdrop-filter: blur(10px);
    transition: 0.3s;
    box-shadow: 1px 1px 2px rgba(0,0,0,.1);
}

header .logo {
    width: 120px;
    height: 70px;
    margin-top: 0;
    margin-left: 30px;
    display: inline-block;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
}

header .menu {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 70px;
    padding-right: 30px;
    text-align: right;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

header .menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 30px;
}

header .menu ul li.li-primary {
    display: inline-block;
    font-size: 16px;
    position: relative;
    margin: 0 5px;
}

header .menu ul li.li-primary a {
    color: var(--header-link-color);
    text-decoration: none;
    padding: 10px;
}

header .menu ul li.li-primary a:hover {
    color: #5887da;
}

header .menu ul li.li-primary.active {
    font-weight: 600;
}

header .submenu {
    width: 250px;
    left: 50%;
    top: 100%;
    background:  var(--submenu-bg);
    box-shadow: 0px 4px 22px rgb(15 18 32 / 10%);
    z-index: -1;
    padding: 0;
    position: absolute;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transform: translate(-50%, 5px);
    padding-top: 14px;
    padding-bottom: 14px;
    transition: all linear 0.2s;
}

header .menu li.li-primary:hover .submenu {
    opacity: 1;
    visibility: inherit;
}

header .menu .submenu span {
    width: 100%;
    text-align: left;
    line-height: 46px;
    font-weight: 500;
    font-size: 16px;
    display: block;
    padding-right: 20px;
    padding-left: 20px;
}

header .menu .submenu span svg {
    width: 20px;
}

header .menu .submenu span.active {
    background-color: #f8f8f8;
}

@media (max-width: 780px) {
    header {
        background-color: var(--header-bg-mobile);
        z-index: 9;
        backdrop-filter: blur(10px);
        transition: 1.3s;
        transition-property: all;
        transition-timing-function: ease;
        transition-property: height;
        transition-timing-function: cubic-bezier(.23, 1, .32, 1);
        will-change: height;
        overflow: hidden;
    }

    header.menu--active {
        height: 100vh;
    }

    header .menu {
        opacity: 0.1;
        transition: 1.3s;
        transition-property: all;
        transition-timing-function: ease;
        transition-property: opacity;
        transition-timing-function: cubic-bezier(.23, 1, .32, 1);
        will-change: opacity;
        padding: 100px 20px 60px;
    }

    header.menu--active .menu {
        opacity: 1;
    }

    #burger--menu {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;
        height: 30px;
        cursor: pointer;
        z-index: 2;
    }

    #burger--menu::before {
        display: block;
        content: "";
        border-top: 2px solid var(--header-link-color);
        border-bottom: 2px solid var(--header-link-color);
        height: 10px;
        margin-top: 8px;
        transition: all 0.2s ease-in;
    }

    header.menu--active #burger--menu::before {
        height: 0;
        margin-top: 16px;
        border-width: 1px;
    }

    header .menu ul {
        margin: 0;
        padding: 0;
        text-align: left;
    }

    header .menu ul li.li-primary {
        display: block;
        margin: 0;
        padding: 20px;
        line-height: 20px;
        font-weight: 500;
        font-size: 18px;
    }

    header .submenu {
        display: none;
        position: static;
        width: 100%;
        z-index: 0;
        visibility: visible;
        transform: none;
        box-shadow: none;
        opacity: 1;
        border-top: 1px solid #eee;
        padding-top: 0;
        margin-top: 15px;
        background-color: #f9f9f9;
    }

    header .menu li.li-primary:hover .submenu {
        display: block;
    }

    header .menu .submenu span {
        padding-right: 7px;
        padding-left: 7px;
    }
}