#slide-bg-formafarma {
	background-color: transparent;
	color: #fff;
	width: 100%;
	min-height: calc(100vh - 70px);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	background-size: cover;
}

#formafarma-particles {
	position: absolute;
	text-align: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	background-color: rgba(0,0,0,0.5);
}

#formafarma-particles canvas {
	left: 0;
	top: 0;
}

#slide-formafarma-content {
	z-index: 0;
}

#formafarma table {
	table-layout: fixed;
	width: 100%;
}

#formafarma table td {
	vertical-align: top;
	padding: 20px;
}

#formafarma table td h5 {
	text-transform: uppercase;
	font-size: 16px;
	color: #222;
	font-weight: 600;
	letter-spacing: 0.5px;
}

@media only screen and (max-width: 900px) {
	#formafarma table td {
		display: block;
	}
  }