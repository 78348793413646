#slide-bg-boh {
	background-color: transparent;
	color: #fff;
	width: 100%;
	min-height: calc(100vh - 70px);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	background-size: cover;
}

#boh-particles {
	position: absolute;
	text-align: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	background-color: rgba(0,0,0,0.5);
}

#boh-particles canvas {
	left: 0;
	top: 0;
}

#slide-boh-content {
	z-index: 0;
}